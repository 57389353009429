<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div class="banner1">
          <img v-lazy="require('../assets/imgs/big_data/banner.webp')" alt="" />
        </div>
        <!-- 横幅内容区域 -->
        <div class="banner2">
          <h2 class="title">智慧景区大数据</h2>
          <p class="introduce">
            智慧景区大数据提供了涵盖大数据基础设施和大数据应用在内的丰富产品及服务，助力客户快速构建企业级数据架构，获取数据时代核心竞争优势
          </p>
        </div>
      </div>
      <!-- 方案优势区域 -->
      <p class="title_h2">方案优势</p>
      <div class="plan_advantage">
        <div>
          <div>
            <img v-lazy="require('../assets/imgs/big_data/gand_up4.webp')" alt="" />
          </div>
          <h5>雄厚技术支撑</h5>
          <span>
            技术专家团队稳定输出充沛的云端大数据技术能力，为客户提供持续性的支撑与服务。
          </span>
        </div>
        <div>
          <div>
            <img v-lazy="require('../assets/imgs/big_data/gand_up4.2.webp')" alt="" />
          </div>
          <h5>丰富领域积累</h5>
          <span>
            涵盖大数据基础技术、算法、模型及数据方法论在内的丰富数据领域积累，为客户提供完善、成熟的解决方案。
          </span>
        </div>
        <!-- >>>>>>>>>>>>> -->
        <div class="plan_three">
          <div>
            <img v-lazy="require('../assets/imgs/big_data/gand_up4.1.webp')" alt="" />
          </div>
          <h5>广泛场景实践</h5>
          <span>
            社交、游戏、资讯、金融等领域多场景累积的大数据实践经验，为客户提供有效、可靠的业务保障。
          </span>
        </div>
        <!-- >>>>>>>>> -->
        <div class="plan_four">
          <div>
            <img v-lazy="require('../assets/imgs/big_data/gand_up4.3.webp')" alt="" />
          </div>
          <h5>开放云生态</h5>
          <span>
            涵盖运维支撑、数据服务、数据产品在内的全链路云端数据合作生态，为客户提供全方位、全覆盖的云端体验。
          </span>
        </div>
      </div>
      <!-- 大数据应用服务区域 -->
      <div class="data_serve">
        <h4>大数据应用服务</h4>
        <div class="images_box">
          <div class="img_1">
            <div>
              <img v-lazy="require('../assets/imgs/big_data/gand39.webp')" alt="" />
            </div>
            <h3>服务介绍</h3>
            <span>
              企业画像提供全方位企业信息查询服务，构建百亿级企业知识图谱，深度挖掘企业、高管、法人、品牌、产品、地域、产业间的复杂网络关系，洞察企业风险，监控企业发展趋势。
            </span>
          </div>
          <div class="box_imgs">
            <div class="imgs_one">
              <div>
                <img v-lazy="require('../assets/imgs/big_data/rectangle2.webp')" alt="" />
              </div>
              <h6>产品功能</h6>
              <span>
                产业分析、企业基础信息查询、企业洞察（发展、风险、活跃评估）、企业舆情、企业关系图谱。
              </span>
            </div>
            <!-- >>>>>>>>>> -->
            <div class="imgs_one">
              <div>
                <img v-lazy="require('../assets/imgs/big_data/rectangle2.1.webp')" alt="" />
              </div>
              <h6>应用场景</h6>
              <span> 智慧城市、智慧园区、金融监管、金融机构等。 </span>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "optimization_algorithm",
  components: {
    Header,
    Footer,
  },
  data () {
    return {};
  },
  mounted () { },
  methods: {},
};
</script>
<style lang="scss" scoped>
  * {
    box-sizing: border-box;
  }
  .banner {
    height: 600px;
    color: #fff;
    position: relative;
    .banner1 {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      // loading大小
      img[lazy='loading'] {
        width: 200px;
      }
      // 实际图片大小
      img[lazy='loaded'] {
        width: 100%;
        height: 100%;
      }
    }
    .banner2 {
      width: 100%;
      height: 600px;
      position: absolute;
      top: 0;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #000000 130%);
      z-index: 1;
      .title {
        height: 67px;
        font-size: 48px;
        font-weight: 500;
        line-height: 67px;
        letter-spacing: 3px;
        text-align: left;
        position: absolute;
        left: 361px;
        top: 230px;
      }
      .introduce {
        width: 565px;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        text-align: left;
        text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
        position: absolute;
        left: 361px;
        top: 321px;
      }
    }
  }
  .introduce,
  .title {
    animation: sport 0.5s;
    transform: translateY(0px);
  }
  //设置头部文字动画效果
  @keyframes sport {
    0% {
      transform: translateY(80px);
      opacity: 1;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
  .title_h2 {
    font-size: 40px;
    font-weight: 500px;
    color: #333;
    letter-spacing: 1px;
    margin-bottom: 60px;
    margin-top: 80px;
  }
  .plan_advantage {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1200px;
    height: 1398px;
    margin: auto;
    margin-bottom: 100px;
    div {
      width: 585px;
      height: 684px;
      text-align: left;
      box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      div {
        width: 585px;
        height: 420px;
        display: flex;
        justify-content: center;
        align-items: center;
        // loading大小
        img[lazy='loading'] {
          width: 150px;
        }
        // 实际图片大小
        img[lazy='loaded'] {
          width: 100%;
          height: 100%;
        }
      }
      h5 {
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        letter-spacing: 1px;
        margin: 48px 0 15px 36px;
      }
      span {
        display: inline-block;
        margin-left: 36px;
        width: 513px;
        font-size: 24px;
        font-weight: 400;
        color: #999;
        letter-spacing: 1px;
      }
    }
    .plan_three,
    .plan_four {
      margin-top: 30px;
    }
  }
  .data_serve {
    width: 100%;
    height: 1181px;
    background: #f6f8fb;
    padding-top: 80px;
    h4 {
      font-size: 40px;
      font-weight: 500;
      color: #333;
      letter-spacing: 1px;
      margin-bottom: 60px;
    }
    .images_box {
      width: 1200px;
      height: 885px;
      margin: auto;
      .img_1 {
        position: relative;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 420px;
        border-top: 1px solid #f6f8fb;
        div {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          // loading大小
          img[lazy='loading'] {
            width: 200px;
          }
          // 实际图片大小
          img[lazy='loaded'] {
            width: 100%;
            height: 100%;
          }
        }
        h3 {
          position: absolute;
          top: 144px;
          font-size: 40px;
          font-weight: 400;
          color: #fff;
          letter-spacing: 1px;
        }
        span {
          position: absolute;
          top: 212px;
          display: inline-block;
          width: 937px;
          font-size: 20px;
          font-weight: 400;
          color: #fff;
          letter-spacing: 1px;
        }
      }
      .box_imgs {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 435px;
        margin-top: 30px;
        .imgs_one {
          width: 585px;
          height: 100%;
          text-align: left;
          box-shadow: 2px 4px 12px 2px rgba(0, 0, 0, 0.1);
          border-radius: 6px;
          div {
            width: 100%;
            height: 260px;
            margin-bottom: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            // loading大小
            img[lazy='loading'] {
              width: 100px;
            }
            // 实际图片大小
            img[lazy='loaded'] {
              width: 100%;
              height: 100%;
            }
          }
          h6 {
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            margin-left: 32px;
            margin-bottom: 16px;
          }
          span {
            display: inline-block;
            margin-left: 32px;
            width: 521px;
            font-size: 20px;
            font-weight: 400;
            color: #999;
          }
        }
      }
    }
  }
</style>
